/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "pushMenu" }] */

import pushMenu from 'is-mobile-nav';

export default () => {

  if ($('.events-header').length) {

    $('.js-mobile-trigger').on('click', (e) => {
      e.preventDefault();
      $(e.currentTarget).toggleClass('-active');
    });

    // Closes menu on esc keypress
    $('html').on('keyup', (e) => {
      // escape key maps to keycode `27`
      if (e.keyCode === 27) {
        $('html').removeClass('-js-menu-open');
      }
    });

    $('.js-mobile-menu-inner').pushMenu({
      menu: '.events-mobile-nav',
      mobileNav: '.js-mobile-menu',
      navButton: '.js-mobile-trigger',
      pageContainer: '.page-container',
      scrollBlocker: '.blocker',
      nextHTML: '<a href="#" class="subopen" data-direction="next"></a>',
      prevHTML: '<li class="nav-back"><a class="subclose" data-direction="prev" href="#">Back</a></li>'
    });

    // append utility links
    const eventUtilityNavs = $('.events-header-utility-nav-right, .events-header-utility-nav-left');
    $(eventUtilityNavs.get().reverse())
      .clone()
      .appendTo('.js-mobile-menu-inner ul[data-position="0"]')
      .addClass('js-mobile-utility-nav')
    ;
  }
};
