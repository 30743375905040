/* eslint-disable no-inner-declarations */
export default () => {

  if ($('.site-header').length) {

    const $window = $(window),
          $html = $('html'),
          $navWrapper = $('.site-header-nav-wrapper'),
          $logo = $('.site-header-logo'),
          $fullWidthDropdown = $('.site-header-main-nav-dropdown.-full-width'),
          $searchBox = $('.js-search-box')
    ;

    let logoLeft,
        navLeft,
        negLeft,
        windowWidth = $window.width()
    ;

    // Position full-width dropdowns on load
    positionLeft();

    // Position full-width dropdowns on resize
    $window.on('resize', () => {
      windowWidth = $window.width();
      if (windowWidth >= 1030 && windowWidth < 1600) {
        positionLeft();
      }
    });

    // Function to control full-width dropdown position
    function positionLeft() {
      logoLeft = $logo.offset().left;
      navLeft = $navWrapper.offset().left;
      negLeft = (navLeft - logoLeft) * -1;
      $fullWidthDropdown.css('left', negLeft);
    }

    // toggle display of main and utility nav dropdowns on focus and blur
    const $navItems = $('.site-header-main-nav-item, .site-header-utility-nav-item');
    $navItems.each((index, navItem) => {
      $(navItem)
        .on('focus', ':focusable', (e) => {
          $navItems.removeClass('-dropdown-open');
          $(e.delegateTarget).addClass('-dropdown-open');
        })
        .on('blur', ':focusable', (e) => {
          $(e.relatedTarget).parents(navItem).siblings().removeClass('-dropdown-open');
        })
      ;
    });

    // toggle display of dropdown on dropdown hover
    $('.site-header-main-nav-dropdown, .site-header-utility-nav-link.-join, .site-header-utility-nav-dropdown').on('mouseenter mouseleave', '', (e) => {
      const $navItem = $(e.currentTarget).parent();
      e.type === 'mouseenter' ? $navItem.addClass('-dropdown-open') : $navItem.removeClass('-dropdown-open');
    });

    // Opens search box
    $html.on('click', '.js-search-open', (e) => {
      e.preventDefault();
      $searchBox.addClass('-is-open');
      setTimeout(() => {
        $('.js-header-search').focus();
      }, 20);
    });

    // Closes search box on click
    $('.js-search-close').on('click', (e) => {
      e.preventDefault();
      $searchBox.removeClass('-is-open');
    });

    // Closes search box on esc keypress
    $html.on('keyup', (e) => {
      // escape key maps to keycode `27`
      if (e.keyCode === 27) {
        $searchBox.removeClass('-is-open');
      }
    });

  }
};
