/* eslint-disable camelcase */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
/* globals process */

export default () => {
  // function contents go here
  if ($('.ratings').length) {

    const $this = $('.ratings');
    const videoId = $('.ratings').attr('data-videoid');
    const getUrl = `${process.env.GET_RATINGS_ENDPOINT}?videoId=${videoId}`;

    const getRating = () => {

      fetch(getUrl)
        .then(res => {
          return res.json();
        })
        .then(data => {
          const {rating, votes} = data.data;
          const currentRating = setAvgRating(rating);

          $this.attr('data-rating', currentRating);
          $('.ratings .ratings-total').text(`${votes} votes`);

          for (let i = 0; i <= currentRating * 2; i++) {
            $(`.ratings label[data-index='${i}']`).addClass('-yellow-hovered');
          }

          for (let i = 10; i > currentRating * 2; i--) {
            $(`.ratings label[data-index='${i}']`).addClass('-gray');
          }

        });
      // .catch(err => {
      //   console.log(err);
      // });
    };

    getRating();

    // stars click event
    $('.ratings label').on('click', e => {

      if (checkCookie()) {
        return;
      }

      // set cookie
      checkCookie();


      // remove current color from stars
      removeColor();

      // grab clicked star index
      const currentIndex = e.currentTarget.dataset.index;
      const ratingValue = e.currentTarget.dataset.value;
      const postUrl = `${process.env.UPDATE_RATINGS_ENDPOINT}?videoId=${videoId}&rating=${ratingValue}`;

      // set all stars after current star to gray
      for (let i = currentIndex; i < 11; i++) {
        $(`.ratings label[data-index='${i}']`).css('color', '#e5e1de');
      }

      // set all stars before current star to yellow
      for (let i = currentIndex; i > 0; i--) {
        $(`.ratings label[data-index='${i}']`).css('color', '#ffd700');
      }

      fetch(postUrl)
        .then(res => {
          return res.json();
        })
        .then((data) => {
          const { votes } = data.data;
          $('.ratings .ratings-total').text(`${votes} votes`);
        });
      // .catch(err => {
      //   console.log(err);
      // });
    });

    // star hover event

    $('.ratings label').on('mouseover', e => {
      const currentIndex = e.currentTarget.dataset.index;

      if (sessionStorage.getItem(`rated_content_${videoId}`)) {
        return;
      }
      // set all stars before current to highlighted yellow
      for (let i = currentIndex; i >= 0; i--) {
        $(`.ratings label[data-index='${i}']`).addClass('-yellow-checked');
      }
    });

    $('.ratings label').on('mouseout', (e) => {
      // remove current class colors to return to default ratings color
      removeColor();
      const currentIndex = e.currentTarget.dataset.index;

      for (let i = currentIndex; i < 11; i++) {
        $(`.ratings label[data-index='${i}']`).removeClass('-gray');
      }

      for (let i = currentIndex; i > 0; i--) {
        $(`.ratings label[data-index='${i}']`).removeClass('-yellow-checked');
      }
    });

    const removeColor = () => {
      $('.ratings label').removeClass('-yellow-checked');
      $('.ratings label').removeClass('-yellow-hovered');
      $('.ratings label').removeClass('-gray');
    };

    const setAvgRating = (num) => {
      let result = 0;

      if ((num - Math.floor(num)) === 0.5) {
        result = num;
      } else if ((num - Math.floor(num)) > 0.5) {
        result = Math.round(num);
      } else {
        result = Math.floor(num);
      }

      return result;
    };

    const checkCookie = () => {
      if (sessionStorage.getItem(`rated_content_${videoId}`)) {
        return true;
      } else {
        sessionStorage.setItem(`rated_content_${videoId}`, true);
      }
    };
  }
};
