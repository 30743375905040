export default () => {

  if ($('.accordion').length) {

    $('.accordion-item-title').on('click', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget),
            $item = $this.parent();
      $this
        .trigger('blur')
        .next()
        .slideToggle(300)
        .parent()
        .attr('data-open', $item.attr('data-open') === 'true' ? 'false' : 'true')
      ;
    });
  }
};
