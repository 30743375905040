// -----------------------------------------------------------------------------
// Component Includes
// -----------------------------------------------------------------------------
import extendjQuery from '@plugins/extend-jquery';
import siteHeader from '@headers/site-header';
import foundationMobileNav from '@components/foundation-mobile-nav';
import eventsHeader from '@headers/events-header';
import eventsMobileNav from '@components/events-mobile-nav';
import formValidation from '@plugins/form-validation';
import scrollAnimate from '@plugins/scroll-animate';
import eventsTabs from '@components/sub-navigation-tabs';
import accordion from '@components/accordion';
import eventAccordion from '@components/event-accordion';
import accordionSponsors from '@components/accordion-sponsors';
import testimonialSlider from '@components/testimonial-slider';
import sponsorsSlider from '@components/sponsors-featured';
import resourcesCarousel from '@components/carousel';
import volunteersListExpander from '@components/volunteers-list';
import eventsFeaturedSession from '@components/events-featured-session';
import NewsList from '@components/news-list';
import alertHeader from '@components/alert-header';
import socialShare from '@components/social-share';
import gdprAlert from '@components/gdpr-alert';
import heroLarge from '@components/hero-large';
import eventsHomeHero from '@components/events-home-hero';
import heroHome from '@components/hero-home';
import heroTopicsPromo from '@components/hero-topics-promo';
import ratings from '@components/ratings';
import coveoFilterController from '@components/search-layout';
import discussion from '@components/discussion';

(($) => {

  // Document Ready
  $(() => {

    // -----------------
    // High-priority JS
    // -----------------
    extendjQuery();
    siteHeader();
    foundationMobileNav();
    alertHeader();
    gdprAlert();

    if ($('.news-list').length) {
      new NewsList().init();
    }
    // -----------------
    // Low-priority JS
    // -----------------
    formValidation();
    // smoothScroll();
    scrollAnimate();
    testimonialSlider();
    resourcesCarousel();
    sponsorsSlider();
    volunteersListExpander();
    eventsHeader();
    eventsMobileNav();
    eventsTabs();
    accordionSponsors();
    accordion();
    eventAccordion();
    eventsFeaturedSession();
    socialShare();
    heroLarge();
    eventsHomeHero();
    heroHome();
    heroTopicsPromo();
    ratings();
    coveoFilterController();
    discussion();
  });

})($);
