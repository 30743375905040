/* eslint-disable no-unused-vars */
import priorityNav from 'priority-nav';

export default () => {
  const nav = priorityNav.init({

    // mainnav wrapper selector (must be direct parent from mainNav)
    mainNavWrapper: '.sub-navigation-tabs-inner',

    // mainnav selector. (must be inline-block)
    mainNav: '.js-priority-nav',

    // class used for the dropdown - this is a class name, not a selector.
    navDropdownClassName: 'sub-navigation-tabs-dropdown',

    // class used for the dropdown toggle - this is a class name, not a selector.
    navDropdownToggleClassName: 'sub-navigation-tabs-dropdown-toggle',

    // Text that is used for the dropdown toggle.
    navDropdownLabel: 'more',

    // button label for navDropdownToggle when the breakPoint is reached.
    navDropdownBreakpointLabel: 'menu',

    // amount of pixels when all menu items should be moved to dropdown to simulate a mobile menu
    breakPoint: 400,

    // this will throttle the calculating logic on resize because i'm a responsible dev.
    throttleDelay: 30,

    // increase to decrease the time it takes to move an item.
    offsetPixels: 0,

    // prints the amount of items are moved to the attribute data-count to style with css counter.
    count: true
  });
};
