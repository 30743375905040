import Pagination from './Pagination';

/**
 * http://yui.github.io/yuidoc/syntax/index.html
 */

const Facets = class Facets extends Pagination {
  /**
   * Primary constructor for the Pagination class.
   *
   * @class Facets
   * @constructor
   * @param { Config } config
   */
  constructor(config) {
    // Call the parent class constructor.
    super(config);

    const { defaultSortParams, facetOptions, orderParam, sortParam } = config;

    this._defaultSortParams = defaultSortParams;
    this._facetOptions      = facetOptions;
    this._orderParam        = orderParam || null;
    this._sortParam         = sortParam || null;

    this._initFacets();
    this._initSorting();
  }

  /**
   * Get the default Sorting parameters that were setup when ethe Class instantiated.
   *
   * @property defaultSortParams
   * @return { Object }
   */
  get defaultSortParams() {
    return this._defaultSortParams;
  }

  /**
   * Get returned facet information from the API response.
   *
   * @property facets
   * @return { Array }
   */
  get facets() {
    return this._meta ? this._meta.facets : [];
  }

  set facets(facets) {
    if (!this.meta) {
      this.meta = {};
    }

    this.meta.facets = facets;
  }

  /**
   * Combine the API facet data with the default facetOptions to build relative
   * facet setttings.
   *
   * @property facetSettings
   * @return { Object }
   */
  get facetSettings() {
    const { facetOptions, facets } = this;

    // Setup settings using the defaul facetOptions.
    let settings = {};
    facetOptions.map(facet => {
      settings[facet.name] = facet;
    });

    // Include additional settings for facet from the API response.
    facets.map(response => {
      if (!settings[response.name]) { return; }
      settings[response.name] = Object.assign(settings[response.name], response);
    });

    return settings;
  }

  /**
   * An array of facet related information that was defined when the Class was
   * instantiated.
   *
   * @property facetOptions
   * @return { Array }
   */
  get facetOptions() {
    return this._facetOptions;
  }

  /**
   * String that identifies the name of the order parameter in the uri.
   *
   * @property orderParam
   * @return { String }
   */
  get orderParam() {
    return this._orderParam;
  }

  /**
   * String that identifies the name of the sort parameter in the uri.
   *
   * @property sortParam
   * @return { String }
   */
  get sortParam() {
    return this._sortParam;
  }

  /**
   * An array of sorting options returned in the API response.
   *
   * @property sorts
   * @return { Array }
   */
  get sorts() {
    return this._meta.sorting;
  }

  _helperUpdateFacet(name, value) {
    const { facetSettings, query } = this,
          current  = query[name] || null,
          settings = facetSettings[name];

    query[this._pagerParam] = 1;

    // If nothing is set the go ahead and set it.
    if (!current) {
      query[name] = value;
      return this._updatePushState();
    }

    // If the value exists go ahead and remove it.
    if (current === value) {
      delete query[name];
      return this._updatePushState();
    }

    // handle facets where isMultiCardinality is set to false.
    if (!settings.isMultiCardinality) {
      delete query[name];
      query[name] = value;
      return this._updatePushState();
    }

    const options = !current ? [] : current.split(','),
          index   = options.indexOf(value);

    if (index >= 0) {
      delete options[index];
    } else {
      options.push(value);
    }

    query[name] = this.compact(options).join(',');
    return this._updatePushState();
  }

  /**
   * Helper method to allow for quickly and easily update sorting parameters.
   *
   * @private
   * @method _helperUpdateSorting
   * @param { String } sort
   * @param { String } order
   * @return undefined
   */
  _helperUpdateSorting(sort, order) {
    const { orderParam, query, sortParam } = this;

    query[orderParam] = order;
    query[sortParam]  = sort;

    this._updatePushState();
  }

  /**
   * Merges the facet parameters into the available queryParams.
   *
   * @private
   * @method _initFacets
   * @return undefined
   */
  _initFacets() {
    const { facetSettings, query, queryParams } = this,
          facets = [];

    Object.keys(facetSettings).map(field => {
      query[field] = facetSettings[field].default;
      facets.push(field);
    });

     let options = queryParams.concat(facets),
         compact = this.compact(options),
         uniqe   = this.uniq(compact);

     this.queryParams = uniqe;
  }

  /**
   * Merges the sorting parameters into the available queryParams.
   *
   * @private
   * @method _initSorting
   * @return undefined
   */
  _initSorting() {
    let { defaultSortParams, orderParam, query, queryParams, sortParam } = this;

    query[orderParam] = defaultSortParams[orderParam];
    query[sortParam]  = defaultSortParams[sortParam];

    let options = queryParams.concat([ orderParam, sortParam ]),
        compact = this.compact(options),
        uniqe   = this.uniq(compact);

    this.queryParams = uniqe;
  }

  /**
   * Determines if a facet or sorting option is currently set in the uri query.
   *
   * @private
   * @method _isFacetActive
   * @param { String } name
   * @param { String } value
   * @return { Boolean }
   */
  _isFacetActive(name, value) {
    const { facetSettings, query } = this,
          settings   = facetSettings[name] || {},
          isBoolean  = settings.type === 'boolean',
          option     = isBoolean ? Boolean(value) : value,
          queryParam = isBoolean ? Boolean(query[name]) : query[name];

    if (settings.isMultiCardinality) {
      return !queryParam ? false : queryParam.split(',').indexOf(value) >= 0;
    }

    return queryParam === option;
  }
};

module.exports = Facets;
