// -----------------------------------------------------------------------------
// Hyperform
//
// Hyperform is a pure JS implementation of the HTML 5 form validation API.
//
// Docs: https://hyperform.js.org/docs
// -----------------------------------------------------------------------------
import hyperform from 'hyperform';

export default () => {

  if ($('form').length) {

    // Override email validation message for invalid email address
    $('form input[type="email"]').map((index, el) => {
      hyperform.setMessage(el, 'typeMismatch', 'Please enter a valid email address.');
    });

    // Override validation message for empty required <select>
    $('form select').map((index, el) => {
      hyperform.setMessage(el, 'valueMissing', 'Please select an option.');
    });

    // Override validation message for meeting checkboxes
    $('form input.meeting-checkbox').map((index, el) => {
      hyperform.setMessage(el, 'valueMissing', 'Please select at least one option');
    });

    $(function() {

      const requiredCheckboxes = $(':checkbox[required]');

      requiredCheckboxes.change(function() {

        if (requiredCheckboxes.is(':checked')) {
          requiredCheckboxes.removeAttr('required');
        } else {
          requiredCheckboxes.attr('required', 'required');
        }
      });
    });


    const container = $('#checkbox-errors');

    hyperform.setRenderer('attachWarning', function(warning, element) {
      if (container.children().length === 0) {
        if (element.hasAttribute('data-meeting-checkbox')) {
          // warning.insertAdjacentText('afterbegin', element.labels[0].textContent);
          container.append(warning);
        }
      }
    });

    // Call hyperform
    const forms = document.forms;
    for (let i = 0; i < forms.length; i++) {
      hyperform(forms[i], {
        classes: {
          warning: 'error-message',
          validated: '-validated',
          valid: '-valid',
          invalid: '-invalid'
        },
        extendFieldset: true
      });
    }
  }
};
