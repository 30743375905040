/* eslint-disable no-unused-vars */
import throttle from '../../js-plugins/throttle';
import Sticky from '../../js-plugins/sticky';
import magnificPopup from 'magnific-popup';

export default () => {

  const components = $('.events-featured-session'),
        stickies = [];

  components.each((index, component) => {
    stickies.push(new Sticky(component, '.events-featured-session-header', {
      top: 0,
      left: 0,
      right: 0
    }));
  });

  $(window).on('scroll', throttle(function() {
    stickies.forEach(sticky => sticky.stick());
  }, 100));

  if ($('.-video').length) {
    $('.events-featured-session-item-image.mfp-iframe').magnificPopup();
  }
};
