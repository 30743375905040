import Search from './Search';

/**
 * http://yui.github.io/yuidoc/syntax/index.html
 */

const Pagination = class Pagination extends Search {
  /**
   * Primary constructor for the Pagination class.
   *
   * @class Pagination
   * @constructor
   * @param { Config } config
   */
  constructor(config) {
    // Call the parent class constructor.
    super(config);

    const { pagerParam } = config;

    this._meta               = null;
    this._pagerParam          = pagerParam || '_page';
    this._pagerSeparator     = { current: false, text: '...', link: null, value: null };

    this._pagerStates = [
      { default: 3, threshold: 5 },
      { default: 3, threshold: 5 },
      { default: 3, threshold: 5 }
    ];
  }

  /**
   * Gets the current page being viewed from the query parameters.
   *
   * @property currentPage
   * @return { Number }
   */
  get currentPage() {
    return Number(this.query[this._pagerParam]);
  }

  /**
   * Returns the number of the first result being viewed. This value is not always
   * one as the first item is dependent on how many pages deep the viewer is.
   *
   * @property firstResult
   * @return { Number }
   */
  get firstResult() {
    const { currentPage, limit } = this;
    return Number(Math.ceil((currentPage * limit) - (limit - 1)));
  }

  /**
   * Determines if the full 3 Group pager is required based on the returned
   * results
   *
   * @property isFullPager
   * @return { Boolean } true|false
   */
  get isFullPager() {
    const { totalPages } = this;
    return totalPages < 8;
  }

  /**
   * Returns the current keywords being searched.
   *
   * @property keywords
   * @return { String }
   */
  get keywords() {
    const { keywordParam } = this;
    return String(this.query[keywordParam]);
  }

  /**
   * Returns the number of the last result being viewed. This value is not the
   * total number of results, as the last item is dependent on what page the
   * viewer is on.
   *
   * @property lastResult
   * @return { Number }
   */
  get lastResult() {
    const { currentPage, limit, totalResults } = this;
    let last = Math.ceil(currentPage * limit);

    return Number((last > totalResults) ? totalResults : last);
  }

  /**
   * Returns the current search limit being used.
   *
   * @property limit
   * @return { Number }
   */
  get limit() {
    return Number(this.query._limit);
  }

  /**
   * Get & Set the pager parameter being used. The pager parameter is the query
   * parameter being used by this API. Default is '_page'.
   *
   * @property pagerParam
   * @return { String }
   */
  get pagerParam() {
    return this._pagerParam;
  }

  set pagerParam(param) {
    this._pagerParam = param;
  }

  /**
   * Get & Set an object containing the default value and threshold value for
   * each of the three pager states being created. It is assumed that there
   * will only ever be 3 pager states.
   *
   * @property pagerStates
   * @return { Object }
   */
  get pagerStates() {
    return this._pagerStates;
  }

  set pagerStates(states) {
    this._pagerStates = states;
  }

  /**
   * Get & Set the ... separator being used between each of the 3 pager states.
   *
   * @property pagerSeparator
   * @return { Object }
   */
  get pagerSeparator() {
    return this._pagerSeparator;
  }

  set pagerSeparator(separator) {
    this._pagerSeparator = separator;
  }

  /**
   * Returns the total number of pages in the current search response.
   *
   * @property totalPages
   * @return { Number }
   */
  get totalPages() {
    return Number(this.meta.totalPages);
  }

  set totalPages(totalPages) {
    if (!this.meta) {
      this.meta = {};
    }

    this.meta.totalPages = Number(totalPages);
  }

  /**
   * Returns the total number of results in the current search response.
   *
   * @property totalResults
   * @return { Number }
   */
  get totalResults() {
    return Number(this.meta.totalResults);
  }

  /**
   * Primary function that builds and returns the pagination results.
   *
   * @method buildPager
   * @return { Array }
   */
  buildPager() {
    let pager = this._pagerGroup1();

    const p2 = this._pagerGroup2(),
          p3 = this._pagerGroup3();

    pager = pager.concat(p2);
    pager = pager.concat(p3);

    return pager;
  }

  /**
   * Helper method that allows for the search results to move to the next page.
   *
   * @method nextPage
   * @return undefined
   */
  nextPage() {
    const { currentPage, totalPages } = this,
          page = (currentPage !== totalPages) ? currentPage + 1 : currentPage;

    this._updateQueryParam(this.pagerParam, page);
  }

  /**
   * Helper method that allows for the search results to move to the previous page.
   *
   * @method previousPage
   * @return undefined
   */
  previousPage() {
    const { currentPage, totalPages } = this,
          page = (currentPage > 1) ? Number(currentPage) - 1 : currentPage;

    this._updateQueryParam(this.pagerParam, page);
  }

  /**
   * Builds Group 1 of the 3 pager groups being used.
   *
   * @private
   * @method _pagerGroup1
   * @return { Array }
   */
  _pagerGroup1() {
    const { currentPage, pagerSeparator, pagerStates, totalPages } = this,
          p1        = pagerStates[1],
          variance  = p1.threshold - p1.default,
          firstPage = 1;

    // Showing only first page.
    let lastPage = firstPage;

    // Showing first 3 pages only.
    if (currentPage < p1.default) {
      lastPage = p1.default;
    }

    // Showing the first 5 pages.
    if (currentPage >= p1.default && currentPage < p1.threshold) {
      lastPage = p1.threshold;
    }

    // Show all 5 if there are only 5 total pages.
    if (currentPage === p1.threshold && totalPages === p1.threshold) {
      lastPage = p1.threshold;
    }

    // Show up to 5 if there are 5 or less pages.
    if (totalPages <= p1.threshold) {
      lastPage = totalPages;
    }

    // Show the next page unless we have reached the end "totalPages".
    lastPage = (lastPage > totalPages) ? totalPages : lastPage;

    let pages = this._buildPagerResults(firstPage, lastPage);
    if (firstPage === lastPage && lastPage !== totalPages) {
      pages = pages.concat([ pagerSeparator ]);
    }

    return pages;
  }

  /**
   * Builds Group 2 of the 3 pager groups being used.
   *
   * @private
   * @method _pagerGroup2
   * @return { Array }
   */
  _pagerGroup2() {
    const { currentPage, isFullPager, pagerSeparator, pagerStates, totalPages } = this,
          p1 = pagerStates[1],
          p3 = pagerStates[2];

    let firstPage = currentPage - 1,
        lastPage  = currentPage + 1,
        pages     = [];

    // Specifically handling a total of 5 pages.
    if (totalPages <= 5) {
      return pages;
    }

    // Common default parameters for a total page count of 6 or 7.
    if (totalPages === 6 || totalPages === 7) {
      firstPage = totalPages;
      lastPage  = totalPages;

      if (currentPage < 3) {
        pages.push(pagerSeparator);
      }
    }

    // Customization for only a total of 6 pages.
    if (totalPages === 6 && currentPage >= 5) {
      firstPage = totalPages - 2;
    }

    // Customization for only a total of 7 pages.
    if (totalPages === 7) {
      if (currentPage === 5) { firstPage = totalPages - 3; }
      if (currentPage > 5)   { firstPage = totalPages - 2; }
      if (currentPage >= 3 && currentPage < 5) { firstPage = lastPage - 1; }
    }

    // Accounts for any page number over or equal to 9.
    if (totalPages >= 8) {
      // Don't show within the p1 threshold.
      if (totalPages === p1.threshold || totalPages < p1.threshold || currentPage < p1.threshold) {
        return pages;
      }

      // Don't show within the p3 threshold.
      if (currentPage > (totalPages - p3.threshold + 1) && !isFullPager) {
        return pages;
      }
    }

    // Show the next page unless we have reached the end "totalPages".
    lastPage = (lastPage > totalPages) ? totalPages : lastPage;

    let response = this._buildPagerResults(firstPage, lastPage);
    pages = pages.concat(response);
    return pages;
  }

  /**
   * Builds Group 3 of the 3 pager groups being used.
   *
   * @private
   * @method _pagerGroup3
   * @return { Array }
   */
  _pagerGroup3() {
    const { currentPage, isFullPager, pagerSeparator, pagerStates, totalPages } = this,
          p1          = pagerStates[0],
          p2          = pagerStates[1],
          p3          = pagerStates[2],
          p3Default   = totalPages - p3.default + 1,
          p3Threshold = totalPages - p3.threshold + 1;

    // Only show group 3 if we have more then 8 pages.
    if (isFullPager) { return []; }

    // Showing only last page.
    let firstPage = totalPages,
        lastPage  = totalPages;

    // Showing last 3 pages only.
    if (currentPage > p3Default) {
      firstPage = p3Default;
    }

    // Showing the last 5 pages.
    if (currentPage === p3Default || (currentPage > p3Threshold && currentPage < p3Default)) {
      firstPage = p3Threshold;
    }

    // Show the next page unless we have reached the end "totalPages".
    lastPage = (lastPage > totalPages) ? totalPages : lastPage;

    let response = (firstPage === lastPage) ? [ pagerSeparator ] : [],
        pages    = this._buildPagerResults(firstPage, lastPage);

    response = response.concat(pages);
    return response;
  }

  /**
   * Formats an individual pager in the required Object.
   *
   * @private
   * @method _buildPagerObject
   * @return { Object }
   */
  _buildPagerObject(link, text, value) {
    return {
      current: Number(this.currentPage) === Number(value),
      link:    link,
      text:    text,
      value:   value
    };
  }

  /**
   * Generates and builds an array containing all the numbers between the
   * firstPage and lastPage.
   *
   * @private
   * @method _buildPagerGroup
   * @param { Number } firstPage
   * @param { Number } lastPage
   * @return { Array }
   */
  _buildPagerGroup(firstPage, lastPage) {
    let options = [];
    for ( let i = firstPage; i <= lastPage; i++) {
      options.push(this._buildPagerObject(null, i, i));
    }

    return options;
  }

  /**
   * Using the first and last pages this loops over each page building the
   * required output.
   *
   * @private
   * @method _buildPagerResults
   * @param { Number } firstPage
   * @param { Number } lastPage
   * @return { Array }
   */
  _buildPagerResults(firstPage, lastPage) {
    let options = this._buildPagerGroup(firstPage, lastPage);
    return options.map(function(page) {
      page.link = this._buildQueryURL(page.text);
      return page;
    }.bind(this));
  }

  /**
   * Constructs the full URL plus query parameters for the specific page.
   *
   * @private
   * @method _buildQueryURL
   * @param { Number } value
   * @return { String }
   */
  _buildQueryURL(value) {
    return this._buildQueryParamsFaker(this.pagerParam, value);
  }

  /**
   * Helper method to allow for quickly and easily going to a page.
   *
   * @private
   * @method _helperUpdatePage
   * @param { Number } page
   * @return undefined
   */
  _helperUpdatePage(page) {
    this.query[this.pagerParam] = page;
    this._updatePushState();
  }
};

module.exports = Pagination;
