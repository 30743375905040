export default () => {
  if ($('.site-header').length) {

    const $mobileNavInner = $('.foundation-mobile-nav-inner');

    $('.js-mobile-trigger').on('click', (e) => {
      e.preventDefault();
      $('html').toggleClass('-js-foundation-menu-open');
      $mobileNavInner.attr('data-level', '1').height('');
    });

    $('.js-mobile-close, .blocker').on('click', (e) => {
      e.preventDefault();
      $('html').removeClass('-js-foundation-menu-open');
      $mobileNavInner.attr('data-level', '1').height('');
    });

    // Closes menu on esc keypress
    $('html').on('keyup', (e) => {
      // escape key maps to keycode `27`
      if (e.keyCode === 27) {
        $('html').removeClass('-js-foundation-menu-open');
      }
    });

    // Go to second-level on next arrow click
    $('.foundation-mobile-nav-level-one-child-trigger').on('click', (e) => {
      e.preventDefault();

      // get index of the menu item in the top-level menu
      const targetPanel = $(e.currentTarget).parent().index(),
            // all second level panels
            $lvlTwoPanels = $('.foundation-mobile-nav-level-two'),
            // the panel matching the index of the clicked top-level menu item
            $targetPanel = $lvlTwoPanels.eq(targetPanel)
      ;

      // make the inner's height match the child's height
      $mobileNavInner.outerHeight($targetPanel.outerHeight());

      // hide all child panels
      $lvlTwoPanels.attr('data-active', 'false');

      // show the panel matching the index of the clicked top-level menu item
      $targetPanel.attr('data-active', 'true');

      // change current level to 2 to translate the menu via css
      $mobileNavInner.attr('data-level', '2');
    });

    // Go to top-level on back click by translating menu via css
    $('.foundation-mobile-nav-back a').on('click', (e) => {
      e.preventDefault();
      $mobileNavInner.attr('data-level', '1').height('');
    });
  }
};
