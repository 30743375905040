/* ********************************************************************

Create :focusable selector for jQuery

Usage examples:

$(:focusable).on('focus', () => { ... });
$(.element > :focusable).on('focus', () => { ... });
$variable.find(':focusable').on('focus', () => { ... });

******************************************************************** */
export default () => {

  $.extend($.expr[':'], {
    focusable(el) {
      return $(el).is('a, button, :input, [tabindex]');
    }
  });

};
