export default () => {
  const clickHandlers = () => {
    $('.discussion-comment-reply-link').off('click');
    $('.discussion-delete').off('click');

    $('.discussion-comment-reply-link').on('click', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      $this.next().slideToggle();

      if ($this.text() === 'Reply') {
        $this.html('Cancel');
      } else {
        $this.html('Reply');
      }
    });

    $('.discussion-delete').on('click', (e) => {
      e.preventDefault();

      const $this = $(e.currentTarget);
      const commentId = $this.attr('comment-id');
      signalRHubProxy.server.deleteComment(commentId);
    });

    $('.post-reply').on('click', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      const comment = `${$this.siblings('textarea').val()}`;
      const parentCommentId = `${$this.attr('comment-id')}`;
      const userName = $('.discussion').attr('user-name');
      if (comment.length) {
        signalRHubProxy.server.addSubComment(userName, comment, parentCommentId);
        $('#reply' + parentCommentId).val('');
        $('#reply' + parentCommentId).parent().slideToggle();
        const replylink = $('#reply' + parentCommentId).parent().siblings('.discussion-comment-reply-link');
        if (replylink.text() === 'Reply') {
          replylink.html('Cancel');
        } else {
          replylink.html('Reply');
        }
      }
    });
  };

  if ($('.discussion').length) {
    clickHandlers();
    $.each($('.discussion-comment-text'), (i, item) => {
      const $this = $(item);
      const textLength = $this.html().length;
      const content = $this.html();
      if (textLength > 1000) {
        $this.addClass('-hide-text');
        const initialText = content.substr(0, 999);
        const hiddenText = content.substr(999, content.length - 1000);
        const html = initialText + `<span class='hidden-text'>${hiddenText}</span><a href='#' class='more-text'>More...</a>`;
        $this.html(html);
      }
    });
    if ($('.more-text').length) {
      $('.more-text').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        if ($this.html() === 'More...') {
          $this.siblings('.hidden-text').css('display', 'inline');
          $this.html('Less...');
        } else {
          $this.siblings('.hidden-text').css('display', 'none');
          $this.html('More...');
        }
      });
    }
  }

  const signalRHubProxy = $.connection.signalRHub;
  signalRHubProxy.client.receivedNewComment = function(renderingID, commentID, commentMsg, commentUserName, commentDate) {
    const clientRenderingId = $('.discussion').attr('rendering-id');
    const userName = $('.discussion').attr('user-name');
    if (clientRenderingId === renderingID) {
      const discussionCommentElements = $('.discussion-comments');
      if (discussionCommentElements.length) {
        $('.discussion-comments').prepend('<div class="discussion-comment" comment-id="' + commentID + '" id="comment' + commentID + `">
            <div class= "discussion-comment-profile" >
            <span class="discussion-comment-name">` + commentUserName + `</span>
            <span class="discussion-comment-time">` + commentDate + `</span>
                            </div >
            <p class="discussion-comment-text">` + commentMsg + `</p>
            <a href="#" class="discussion-comment-reply-link">Reply</a>
            <div class="discussion-comment-reply-input">
                <textarea placeholder="Add your comment..." name="comment" id="reply` + commentID + `" cols="30" rows="5" maxlength="2000"></textarea>
                <a href="#" class="discussion-submit btn post-reply" comment-id="` + commentID + '" user-name="' + userName + `">post comment</a>
            </div>
            <div class="discussion-comment-replies">
            </div>
        </div> `);
        clickHandlers();
      } else {
        $('.discussion-inner').append(`<div class="discussion-comments">
        <div class="discussion-comment" comment-id="` + commentID + '" id="comment' + commentID + `">
          <div class= "discussion-comment-profile" >
          <span class="discussion-comment-name">` + commentUserName + `</span>
          <span class="discussion-comment-time">` + commentDate + `</span>
                          </div >
          <p class="discussion-comment-text">` + commentMsg + `</p>
          <a href="#" class="discussion-comment-reply-link">Reply</a>
          <div class="discussion-comment-reply-input">
              <textarea placeholder="Add your comment..." name="comment" id="reply` + commentID + `" cols="30" rows="5" maxlength="2000"></textarea>
              <a href="#" class="discussion-submit btn post-reply" comment-id="` + commentID + '" user-name="' + userName + `">post comment</a>
          </div>
          <div class="discussion-comment-replies">
          </div>
      </div>
    </div>`);
        clickHandlers();
      }
    }
  };
  signalRHubProxy.client.receivedNewSubComment = function(parentCommentID, commentID, commentMsg, commentUserName, commentDate) {
    const userName = $('.discussion').attr('user-name');
    const parentCommentId = '#comment' + parentCommentID;
    const parentComment = $(parentCommentId);
    if (parentComment.length) {
      const repliesElements = parentComment.children('.discussion-comment-replies');
      if (repliesElements.length) {
        repliesElements.prepend(`
<div class="discussion-comment -reply" comment-id="` + commentID + '" id="comment' + commentID + `">
  <div class="discussion-comment-profile">
      <span class="discussion-comment-name">` + commentUserName + `</span>
      <span class="discussion-comment-time">` + commentDate + `</span>
  </div>
  <p class="discussion-comment-text">` + commentMsg + `</p>
  <a href="#" class="discussion-comment-reply-link">Reply</a>
  <div class="discussion-comment-reply-input">
      <textarea placeholder="Add your comment..." name="comment" id="reply` + commentID + `" cols="30" rows="5" maxlength="2000"></textarea>
      <a href="#" class="discussion-submit btn post-reply" comment-id="` + commentID + '" user-name="' + userName + `">post comment</a>
  </div>
</div> `);

        clickHandlers();
      } else {
        parentComment.append(`<div class="discussion-comment-replies">
  <div class="discussion-comment -reply" comment-id="` + commentID + '" id="comment' + commentID + `">
      <div class="discussion-comment-profile">
          <span class="discussion-comment-name">` + commentUserName + `</span>
          <span class="discussion-comment-time">` + commentDate + `</span>
      </div>
      <p class="discussion-comment-text">` + commentMsg + `</p>
      <a href="#" class="discussion-comment-reply-link">Reply</a>
      <div class="discussion-comment-reply-input">
          <textarea placeholder="Add your comment..." name="comment" id="reply` + commentID + `" cols="30" rows="5" maxlength="2000"></textarea>
          <a href="#" class="discussion-submit btn post-reply" comment-id="` + commentID + '" user-name="' + userName + `">post comment</a>
      </div>
  </div>
</div>`);

        clickHandlers();
      }
    }
  };

  signalRHubProxy.client.deletedComment = function(commentId) {
    const comment = $('#comment' + commentId);
    if (comment.length) {
      comment.remove();
    }
  };

  $.connection.hub.start()
    .done(function() {
      $('.post-comment').on('click', (e) => {
        e.preventDefault();

        const $this = $(e.currentTarget);

        const data = {};
        const comment = `${$this.siblings('textarea').val()}`;
        const renderingId = `${$this.attr('rendering-id')}`;
        const userName = $('.discussion').attr('user-name');

        if (comment.length) {
          data.commentMsg = comment;
          data.renderingId = renderingId;

          // Call the Send method on the hub.
          signalRHubProxy.server.addComment(userName, comment, renderingId);
          // Clear text box and reset focus for next comment.
          $('#comment').val('').focus();
        }
      });
      $('.post-reply').on('click', (e) => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        const comment = `${$this.siblings('textarea').val()}`;
        const parentCommentId = `${$this.attr('comment-id')}`;
        const userName = $('.discussion').attr('user-name');
        if (comment.length) {
          signalRHubProxy.server.addSubComment(userName, comment, parentCommentId);
          $('#reply' + parentCommentId).val('');
          $('#reply' + parentCommentId).parent().slideToggle();
          const replylink = $('#reply' + parentCommentId).parent().siblings('.discussion-comment-reply-link');
          if (replylink.text() === 'Reply') {
            replylink.html('Cancel');
          } else {
            replylink.html('Reply');
          }
        }
      });

      clickHandlers();
    })
    .fail(function() {
      /* eslint-disable no-console */
      console.error('Could not Connect!');
    });
};
