/* eslint-disable no-unused-vars */
import slick from 'slick-carousel';
import magnificPopup from 'magnific-popup';

export default () => {
  if ($('.carousel-content-slides .resources-promo-item').length && $('.carousel-content-slides > .resources-promo-item').length > 4) {
    $('.carousel-content-slides').addClass('carousel-content-slider-js');
    $('.carousel-content-slides .resources-promo-item').addClass('carousel-content-slide-js');

    $('.carousel-content-nav').each(function(index) {
      $(this).attr('data-slider', index);
    });

    $('.carousel-content-slides').each(function(index) {

      $(this).attr('data-slider', index);

      $(this).slick({
        autoplay: true,
        arrows: true,
        autoplaySpeed: 4000,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        fade: false,
        pauseOnHover: true,
        draggable: true,
        centerMode: true,
        centerPadding: '0',
        cssEase: 'ease',
        appendArrows: $('[data-slider="' + index + '"] .carousel-content-nav'),
        prevArrow: $('[data-slider="' + index + '"] .carousel-content-nav-prev'),
        nextArrow: $('[data-slider="' + index + '"] .carousel-content-nav-next'),
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1230,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    });
  }
};
