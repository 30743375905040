// sticky plugin
export default class Sticky {

  constructor(component, stickyItemSelector, styles) {
    this.component = $(component);
    this.styles = $.extend({
      position: 'fixed',
      zIndex: 99
    }, styles);
    this.stickyClone = this.component.find(stickyItemSelector)
      .clone(true)
      .addClass('-clone')
      .css(this.styles);
  }

  stick() {
    const { top, bottom } = this.component[0].getBoundingClientRect();

    if (top <= 0 && bottom > 0) {
      this.component.append(this.stickyClone);
    } else {
      this.component.find('.-clone').remove();
    }
  }
}
