export default () => {
  // function contents go here
  let alertValue = sessionStorage.getItem('alert-header');
  if ($('.alert-header').length) {
    // set to local storage
    if (!alertValue) {
      sessionStorage.setItem('alert-header', 'true');
      alertValue = sessionStorage.getItem('alert-header');
    }

    if (alertValue && alertValue === 'true') {
      $('.alert-header').css('transition', 'max-height 0s');
      $('.alert-header').addClass('-show-alert');
      $('.-latest').addClass('-hide-latest');
      $('.-latest').html('Hide the latest');
    }

    $('.alert-header-promo-icon-close').on('click', () => {
      // remove from session storage
      sessionStorage.setItem('alert-header', 'false');
      $('.alert-header').removeClass('-show-alert');
      $('.-latest').html('Show the latest');
      $('.-latest').removeClass('-hide-latest');
      $('.alert-header').css('transition', 'max-height .4s cubic-bezier(0, 1, 0, 1)');
    });

  }

  if ($('.-latest').length) {

    $('.-latest').on('click', (e) => {
      e.preventDefault();
      $('.alert-header').toggleClass('-show-alert');

      if ($('.alert-header.-show-alert').length) {
        $('.-show-alert').css('transition', 'max-height 1.5s ease-in-out');
        $('.-latest').html('Hide the latest');
        $('.-latest').addClass('-hide-latest');
      } else {
        $('.-latest').html('Show the latest');
        $('.-latest').removeClass('-hide-latest');
        $('.alert-header').css('transition', 'max-height .4s cubic-bezier(0, 1, 0, 1)');
      }
    });
  }

};
