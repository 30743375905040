/* eslint-disable no-unused-vars */
import slick from 'slick-carousel';

export default () => {

  if ($('.testimonial-slider-slide').length) {

    $('.testimonial-slider').addClass('-slider-padding');

    $('.testimonial-slider-slides').slick({
      dots: true,
      arrows: false,
      fade: true,
      autoplay: false,
      autoplaySpeed: 5000
    });
  }
};

