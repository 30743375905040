import throttle from '@plugins/throttle';

export default () => {

  if ($('.js-scroll-animate').length) {

    $(window).on('scroll', animateOnScroll);

    $(window).on('resize', throttle(animateOnScroll, 100));

    animateOnScroll();

  }

};

function animateOnScroll() {
  const scrolled = $(window).scrollTop(),
        winHeightPadded = $(window).height() * 1;

  // Shown...
  $('.js-scroll-animate:not(.-animated)').map((index, el) => {
    if (scrolled + winHeightPadded > $(el).offset().top) {
      if ($(el).data('timeout')) {
        window.setTimeout(() => {
          $(el).addClass('-animated');
        }, parseInt($(el).data('timeout'), 10));
      } else {
        $(el).addClass('-animated');
      }
    }
  });
}
