/* eslint-disable */
export default () => {
  // content goes here

  if ($('.search-layout').length) {

    let open = false;

    const $controller = $('.search-layout-filters-controller'),
          $filters = $('.search-layout-filters');

    $(window).resize(() => {
      if (window.innerWidth >= 900) {
        $filters.css('display', 'block');
        return;
      }

      if (window.innerWidth < 900 && open) {
        $filters.css('display', 'block');
        $('.coveo-dropdown-header-wrapper').css('display', 'none');
        return;
      }

      if (window.innerWidth < 900 && !open) {
        $filters.css('display', 'none');
        $('.coveo-dropdown-header-wrapper').css('display', 'none');
      }
    });

    $controller.click((e) => {
      e.preventDefault();

      if (open) {
        open = false;
        $controller.removeClass('-opened');
        $controller.addClass('-closed');
        $filters.slideToggle();
      } else {
        open = true;
        $controller.removeClass('-closed');
        $controller.addClass('-opened');
        $filters.slideToggle();
      }
    });
  }

  const setNoResultsText = (el) => {
    let root = Coveo.$$(document).find(el); // replace the value within the brackets with the value of your Search Interface component's DOM Unique Id in its datasource.
    var noResultString = `
    <h3>Search Tips</h3>
    <p><strong>Tip 1</strong>: Search on detailed terms using complete words and avoid broad or partial terminology. </p>
    <p><strong>Tip 2:</strong> Punctuation can be used to enhance your search:</p>
    <ul>
        <li>Use quotes ("subluxated lens") to search exact terms.</li>
        <li>Add an asterisk (cornea*) as a placeholder for any wildcard terms. In this case, a search would return results including cornea, corneas, corneal, etc.</li>
    </ul>
    <p><strong>Tip 3</strong>: This search defaults to an AND search automatically, meaning if you type in two terms, the search will return only results containing both of those terms. For results including either term, add in &ldquo;OR&rdquo; between the terms (ex. &ldquo;subluxated OR lens&rdquo;).<br />
    <em>Note: At this time, the search does not support word elimination or &ldquo;NOT&rdquo; search functionality.</em></p>
    <p><strong>Tip 4</strong>: Avoid capitalization and conjunctions like "the," or "in." Also, avoid using media types like "video," "article," and "image."</p>
    <p><strong>Tip 5</strong>: Your results can be refined by using the &ldquo;sort by&rdquo; dropdown in the upper right-hand corner (Relevancy, Most Recent), or the filters on the left (by topic, by type, by meeting, or by date).</p>
    <p><strong>Tip 6</strong>: Once you have applied various filters to a search, a new search term entered into the Search box will retain those filters until the &ldquo;Clear All Filters&rdquo; button on the left-hand side is clicked.</p>
    <p>We are always trying to improve the website content and search capabilities. If you are having any trouble with search or would like to provide feedback to us, please contact us</p>
  `; // this is just a sample to demonstrate that this would work. This would hold the value of your text, with the necessary tags for paragraphs and styling.
    Coveo.$$(root).on("deferredQuerySuccess", function(e, data) {
      if(data.results.totalCount == 0) {
        Coveo.$$(document).find(".coveo-query-summary-no-results-string").innerHTML = noResultString;
      }
    });
   }

   if($('[data-search-interface-id="coveo4806c423"]').length) {
    setNoResultsText("#coveo4806c423");
   }

   if($('[data-search-interface-id="coveo3af77b37"]').length) {
    setNoResultsText("#coveo3af77b37");
   }

   if($('[data-search-interface-id="coveoa84282d5"]').length) {
    setNoResultsText("#coveoa84282d5");
   }
};
