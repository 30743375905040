export default () => {

  if ($('.events-header').length) {

    $('html')
      // toggle class for dropdown open and close
      .on('mouseenter mouseleave focus blur', '.js-events-header-dropdown a, .events-header-day-changer-dropdown', (e) => {
        e.type === 'mouseenter' || e.type === 'focusin' ? $(e.currentTarget).parents('.js-events-header-dropdown').addClass('-dropdown-open') : $(e.currentTarget).parents('.js-events-header-dropdown').removeClass('-dropdown-open');
      })

      // prevent click behavior on day changer link
      .on('click', '.events-header-day-changer-link', (e) => {
        e.preventDefault();
      })
    ;
  }
};
