/* eslint-disable camelcase */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
/* globals process */

import {Config, Facets, Pagination} from 'is-search';

export default class NewsList extends Facets {
  /**
   * Initialize the Search.
   */
  constructor() {
    const config = new Config();

    const year = null;
    const category = null;

    const listingPage = document.getElementById('newslisting');
    const itemid = listingPage.dataset.itemId;

    config.endpoint           = process.env.NEWS_ENDPOINT;
    config.searchURL          = window.location.pathname;
    config.defaultQueryParams = { _limit: 10, _page: 1, _sort: 'date', _order: 'desc', category, _itemid: itemid };
    config.pagerParam         = '_page';

    config.facetOptions = [{
      name: 'category',
      isMultiCardinality: false,
      default: category
    }];

    super(config);

    // Event callback for Fetch requests which build the reults and pager.
    $(this)
      .on('isLoading', this._mixinIsLoading.bind(this))
      .on('HandleResponse', this._searchResponse.bind(this))
      .on('HandleResponse', this._searchBuildFacets.bind(this))
      .on('HandleResponse', this._mixinBuildPagination.bind(this))
    ;

    $('html').on('change', '.news-list-facets-section select', this._mixinFacetSelectTrigger.bind(this));

    // Pagination
    this._searchContainer = '.news-list-results';
    this.mixinPagination();

  }

  get searchContainer() {
    return this._searchContainer || null;
  }

  set searchContainer(container) {
    this.searchContainer = container;
  }

  mixinPagination() {
    // UI based click events for pagination.
    $('.news-list .pagination')
      .on('click', '.pagination-prev', this.previousPage.bind(this))
      .on('click', '.pagination-next', this.nextPage.bind(this))
      .on('click', '.pagination-pages a', this._mixinPaginationTrigger.bind(this))
      .on('click', 'a', this._mixinPaginationScrollTop.bind(this))
    ;
  }


  /**
   * Callback for build the pagintation based off the returned results.
   *
   * @private
   * @method _mixinBuildPagination
   */
  _mixinBuildPagination() {

    $('.news-list .pagination-pages').empty();

    // Hide pagination arrows by default.
    // We trigger this once in the constructor() for the inital page load,
    // and this one if for sequential action.
    $('.news-list .pagination-nav a').hide();

    // Do not load the pager if we get 1 or less pages to display.
    if (this.totalPages <= 1) {
      return;
    }

    $('.news-list .pagination').show();

    $('.news-list .pagination-nav a').show().attr('data-disabled', 'false');

    if (this.currentPage === 1) {
      $('.news-list .pagination-prev').attr('data-disabled', 'true');
    }

    if (this.currentPage === this.totalPages) {
      $('.news-list .pagination-next').attr('data-disabled', 'true');
    }

    $('.news-list .pagination-mobile-current').html(this.currentPage);
    $('.news-list .pagination-mobile-total').html(this.totalPages);

    this.buildPager().map(this._mixinBuildPaginationItem);
  }


  /**
   * Callback for building an individual pager item.
   *
   * @private
   * @method _mixinBuildPaginationItem
   * @param { Object } page The page object context for the current search results.
   */
  _mixinBuildPaginationItem(page) {
    const {current, link, text, value} = page,
          currentHTML = `<a href="#" data-value="${value}" data-current="true"><span class="sr-text">Page </span>${text}</a>`;

    let defaultHTML = `<a href="${link}" data-value="${value}"><span class="sr-text">Page </span>${text}</a></li>`;

    if (text === '...') {
      defaultHTML = `<span class="pagination-pages-dots">${text}</span>`;
    }

    $('.news-list .pagination-pages').append(current ? currentHTML : defaultHTML);
  }

  /**
   * Triggers the updating of the search on Facet click.
   *
   * @private
   * @method _mixinFacetSelectTrigger
   * @param { Object } event The jQuery event passed from on().
   */
  _mixinFacetSelectTrigger(event) {
    event.preventDefault();

    const element = $(event.target),
          {name, value} = element[0];

    this._helperUpdateFacet('category', value);
  }

  /**
   * Callback that allows for a progress loaded to be initialized and deinitialized.
   *
   * @private
   * @method _mixinIsLoading
   * @param { Object } event The jQuery event passed from on().
   * @param { Boolean } state true|false
   */
  _mixinIsLoading(event, state) {

    switch (state) {
    case true:
      // Show progress html.
      $('.news-list loader').show();
      $('.news-list-results').hide();
      $('.news-list .pagination').hide();
      break;

    default:
      // Hide progress html.
      $('.news-list loader').hide();
      $('.news-list-results').show();
      break;
    }
  }

  /**
   * Scroll to the top of search on pagination click.
   *
   * @private
   * @method _mixinPaginationScrollTop
   * @param { Object } event The jQuery event passed from on().
   */
  _mixinPaginationScrollTop(event) {
    event.preventDefault();

    $('html, body').animate({
      scrollTop: $('.news-list').offset().top - 50
    }, 300);
  }

  /**
   * Callback being used to as the click evnet for the pagintation.
   *
   * @private
   * @method _mixinPaginationTrigger
   * @param { Object } event The jQuery event passed from on().
   */
  _mixinPaginationTrigger(event) {
    event.preventDefault();

    const page = $(event.target).data('value');

    this._helperUpdatePage(page);
  }


  /**
   * Builds facet HTML on load.
   *
   * @private
   * @method _searchBuildFacets
   */
  _searchBuildFacets() {
    const wrapper = $('.news-list-container');
    wrapper.empty();
    this.facets.map(this._searchBuildFacetItem.bind(this, wrapper));
  }

  /**
   * Builds an individual facet HTML element on load.
   *
   * @private
   * @method _searchBuildFacetItem
   * @param { String } wrapper jQuery element.
   * @param { Object } facet The facet context for one option.
   */
  _searchBuildFacetItem(wrapper, { name, options, id, type }) {
    let data = []
      ;

    /* eslint-disable indent */
    switch (type) {
      case 'select': {
        const selectLabel    = `<label for="${id}">Filter by ${name}:</label><select id="${id}" name="${name}"><option value="" selected>Select ${name}</option>`;
        data = data.concat(selectLabel, options.map(this._searchBuildTypeSelect.bind(this, name)), '</select>');
        break;
      }
    }
    /* eslint-enable indent */
    const ouput = data.join('');

    wrapper.append(`
      <div class="news-list-facets-section -${name}">
        ${ouput}
      </div>
    `);
  }

  /**
   * Formats and constructs an individual checkbox element.
   *
   * @private
   * @method _searchBuildTypeSelect
   * @param { String } name The name of the facet.
   * @param { Object } item The facet option context.
   * @return { HTML } The formated HTML checkbox.
   */
  _searchBuildTypeSelect(name, { label, value, count }) {
    const checked        = this._isFacetActive(name, value) ? ' selected' : '',
          contents       = `<option name="${label}" value="${value}"${checked}>${label}</option>`;
    return contents;
  }

  /**
   * Callback for the primary Fetch responses.
   *
   * @private
   * @method _searchResponse
   * @param { Object } event The jQuery event passed from on().
   * @param { JSON } response The raw response object.
   */
  _searchResponse(event, response) {

    const { results } = response,
          { firstResult, lastResult, totalResults } = this,
          container = $('.news-list-results');

    // Empty out the current search results and stats.
    container.empty();

    // Stop if there are no results to show.
    if (!results.length) {
      $('.news-list-no-results').show();
      $('.news-list-results-sort').hide();
      $('.news-list .pagination').hide();
      return;
    }

    // Hide no results message and show sort
    $('.news-list-no-results').hide();
    $('.news-list-results-sort').show();

    // Build and output each individual search result.
    results.map(({ title, date, url, text, category, image, members_content }) => {
      const summary = text.length >= 153 ? text.substring(0, 150) + '...' : text;
      const template = `
      <a class="news-list-item" href="${url}">
      ${ image ? `<div class="news-list-item-image">
      <img src="${ image }" alt="ASCRS News">
    </div>` : ''}
      <div class="news-list-item-text-wrapper">
        <div class="h3 news-list-item-title ${members_content ? '-lock-button' : ''}">
          ${ title }
        </div>
        <div class="news-list-item-info">
          <span class="news-list-item-category">${ category }</span> |
          <span class="news-list-item-date">${ date }</span>
        </div>
        <p class="news-list-item-text"> ${text} </p>
      </div>
    </a>
      `;

      container.append(template);

    });

  }
}
