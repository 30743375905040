/* eslint-disable no-unused-vars */
import slick from 'slick-carousel';

export default () => {
  if ($('.sponsors-featured-logo').length && $('.sponsors-featured-logo').length > 5) {
    $('.sponsors-featured-logos-wrapper').addClass('sponsors-featured-slider-js');
    $('.sponsors-featured-logo').addClass('sponsors-featured-slide-js');

    // this is for the first fadeout
    setTimeout(() => {
      $('.sponsors-featured-logo-image').addClass('fade-js');
    }, 3500);
    // all the rest of the transitions after the initial
    $('.sponsors-featured div').on('afterChange', (event, slick, currentSlide, nextSlide) => {
      $('.sponsors-featured-logo-image').removeClass('fade-js');
      setTimeout(() => {
        $('.sponsors-featured-logo-image').addClass('fade-js');
      }, 3500);
    });

    $('.sponsors-featured-slider-js').slick({
      autoplay: true,
      arrows: false,
      speed: 1,
      autoplaySpeed: 4000,
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      fade: false,
      pauseOnHover: false,
      draggable: false,
      responsive: [
        {
          breakpoint: 1230,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 670,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

  }
};
