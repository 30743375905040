export default () => {
  // function contents go here
  let alertValue = sessionStorage.getItem('gdpr-alert');
  if ($('.gdpr-alert').length) {
    // set to local storage
    if (!alertValue) {
      sessionStorage.setItem('gdpr-alert', 'true');
      alertValue = sessionStorage.getItem('gdpr-alert');
    }

    if (alertValue && alertValue === 'true') {
      $('.gdpr-alert').addClass('-show-alert');
    }

    $('.gdpr-alert-close').on('click', () => {
      // remove from session storage
      sessionStorage.setItem('gdpr-alert', 'false');
      $('.gdpr-alert').slideToggle();
    });
  }
};
