import Promise from 'promise-polyfill';

if (!window.Promise) {
  window.Promise = Promise;
}

exports.Config     = require('./lib/Config');
exports.Facets     = require('./lib/Facets');
exports.Pagination = require('./lib/Pagination');
exports.Search     = require('./lib/Search');
