/**
 * http://yui.github.io/yuidoc/syntax/index.html
 */

const Config = class Config {
  /**
   * Primary constructor for the Pagination class.
   *
   * @class Config
   * @constructor
   */
  constructor() {
    this._endpoint           = null;
    this._searchURL          = null;
    this._defaultQueryParams = {};
    this._defaultSortParams  = {};
    this._facetOptions       = [];
    this._keywordParam       = 'q';
    this._orderParam         = null;
    this._pagerParam         = '_page';
    this._sortParam          = null;
  }

  /**
   * Get & Set the endpoint use to make all Fetch requests.
   *
   * @property endpoint
   * @return { String }
   */
  get endpoint() {
    return this._endpoint;
  }

  set endpoint(endpoint) {
    this._endpoint = endpoint;
  }

  /**
   * Get & Set the available facets being used to alter Fetch requests.
   *
   * @property facetOptions
   * @return { String }
   */
  get facetOptions() {
    return this._facetOptions;
  }

  set facetOptions(facetOptions) {
    this._facetOptions = facetOptions;
  }

  /**
   * Get & Set the URL used on the frontend UI.
   *
   * @property searchURL
   * @return { String }
   */
  get searchURL() {
    return this._searchURL;
  }

  set searchURL(searchURL) {
    this._searchURL = searchURL;
  }

  /**
   * Get & Set the default query parameters when none are provided.
   *
   * @property defaultQueryParams
   * @return { Object }
   */
  get defaultQueryParams() {
    return this._defaultQueryParams;
  }

  set defaultQueryParams(defaultQueryParams) {
    this._defaultQueryParams = defaultQueryParams;
  }

  /**
   * Get & Set the default sort parameters when none are provided.
   *
   * @property defaultQueryParams
   * @return { Object }
   */
  get defaultSortParams() {
    return this._defaultSortParams;
  }

  set defaultSortParams(defaultSortParams) {
    this._defaultSortParams = defaultSortParams;
  }

  /**
   * Get & Set targets the name of the keyword specific query parameter.
   *
   * @property keywordParam
   * @return { String }
   */
  get keywordParam() {
    return this._keywordParam;
  }

  set keywordParam(keywordParam) {
    this._keywordParam = keywordParam;
  }

  /**
   * Get & Set targets the name of the order specific query parameter.
   *
   * @property orderParam
   * @return { String }
   */
  get orderParam() {
    return this._orderParam;
  }

  set orderParam(orderParam) {
    this._orderParam = orderParam;
  }

  /**
   * Get & Set targets the name of the page specific query parameter.
   *
   * @property pagerParam
   * @return { String }
   */
  get pagerParam() {
    return this._pagerParam;
  }

  set pagerParam(pagerParam) {
    this._pagerParam = pagerParam;
  }

  /**
   * Get & Set targets the name of the sort specific query parameter.
   *
   * @property sortParam
   * @return { String }
   */
  get sortParam() {
    return this._sortParam;
  }

  set sortParam(sortParam) {
    this._sortParam = sortParam;
  }
};

module.exports = Config;
