/* eslint-disable no-unused-vars */
import TweenMax from 'gsap/TweenMax';

export default () => {
  // function contents go here

  $('.hero-topics-promo').mousemove(function(e) {
    parallaxIt(e, '.hero-topics-promo-image .ring', 50);
    parallaxIt(e, '.hero-topics-promo-image .thumbprint-dot', 15);
  });

  function parallaxIt(e, target, movement) {
    const $this = $('.hero-topics-promo');
    const relX = e.pageX - $this.offset().left;
    const relY = e.pageY - $this.offset().top;

    TweenMax.to(target, 1, {
      x: (relX - ($this.width() / 2)) / $this.width() * movement,
      y: (relY - ($this.height() / 2)) / $this.height() * movement
    });
  }

  function parallaxItReverse(e, target, movement) {
    const $this = $('.hero-topics-promo');
    const relX = e.pageX - $this.offset().left;
    const relY = e.pageY - $this.offset().top;

    TweenMax.to(target, 1, {
      x: '-' + ((relX - ($this.width() / 2)) / $this.width() * movement),
      y: '-' + ((relY - ($this.height() / 2)) / $this.height() * movement)
    });
  }
};
