export default () => {

  if ($('.social-share').length) {

    const $shareButton = $('.social-share-action[data-action="share"]');

    $shareButton.on('click', (e) => {
      e.preventDefault();
      $shareButton.attr('data-open', $shareButton.attr('data-open') === 'true' ? 'false' : 'true');
      $('.social-share-social-links').slideToggle(250);
    });

    $('.social-share-action[data-action="print"]').on('click', (e) => {
      e.preventDefault();
      window.print();
      $(e.currentTarget).trigger('blur');
    });

    $('.social-share-social-link:not([data-service="email"])').on('click', (e) => {
      e.preventDefault();
      let url;
      const currPage = window.location.href;

      switch ($(e.currentTarget).data('service')) {
      case 'facebook':
        url = 'https://www.facebook.com/sharer/sharer.php?u=' + currPage;
        break;
      case 'twitter':
        url = 'https://twitter.com/intent/tweet/?url=' + currPage;
        break;
      case 'linkedin':
        url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + currPage;
        break;
      }

      windowPopup(url);
    });

    const email = {
      subject: encodeURIComponent('From ASCRS: ' + document.title),
      body: window.location.href
    };
    $('.social-share-social-link[data-service="email"]').attr('href', 'mailto:?subject=' + email.subject + '&body=' + email.body);
  }

};

function windowPopup(url) {
  const top = (screen.height / 3) - 150;
  const left = (screen.width / 2) - 250;

  // Calculate the position of the popup so it's centered on the screen.
  window.open(
    url,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=500,height=300,top=' + top + ',left=' + left
  );
}
