export default () => {
  // function contents go here
  if ($('.volunteers-list-button').length) {
    $('.volunteers-list-button').click((e) => {
      e.preventDefault();
      $('.volunteers-list-item-wrapper-js').toggleClass('-expand-js');
      if ($('.volunteers-list-item-wrapper-js.-expand-js').length) {
        $('.volunteers-list-button').html('Show Less');
      } else {
        $('.volunteers-list-button').html('Show More');
      }
    });
  }
};
